// Get Related Properties
export const fetchGeoIpCity = async (location) => {
    let city,
        state = '';
    if (Object.keys(location)[0] === 'city' || Object.keys(location)[0] === 'zip') {
        city = location.city.value;
        state = location.city.state;
    }
    let retObject = {
        city: city,
        state: state,
        accuracy: 99999
    };
    if (retObject.city && retObject.city !== 'Wildcard' && retObject.state) {
        retObject.accuracy = 0;
        return retObject;
    }
    try {
        let res = await axios({
            url: '/geoip/getCity',
            method: 'get',
            timeout: 5000
        });
        if (res.data.data.location.accuracy_radius <= 100) {
            let isAmerica = res.data.data.country == 'US';
            if (isAmerica) {
                // Set the City State cookie if it's not set
                let city = res.data.data.city;
                let state = res.data.data.state;
                // this.$_setCityStateCookie(city, state);
                return {
                    city: city,
                    state: state,
                    accuracy: res.data.data.location.accuracy_radius
                };
            }
        }
    } catch (err) {
        console.error(err);
    }
    return retObject;
};
// Get Related Properties
export const getRelatedProperties = async (size, searchParams, currentProperty) => {
    try {
        let res = await axios.post('properties/beta/searchElastic?pageSize=' + size, { searchQuery: searchParams });
        if (res.data.data.data.items.length > 1) {
            res.data.data.data.items.filter((property) => property.listingKey !== currentProperty).slice(0, currentProperty ? 8 : size);
            return res.data.data.data;
        }
    } catch {
        return -1;
    }
};

export const fetchGeocodingBoundaries = async (boundaryQuery, locationType, locationState) => {
    try {
        let res = await axios.post('geocoding/boundaries?boundaryQuery=' + boundaryQuery + '&boundaryType=' + locationType.toLowerCase().replace(/-/g, ' ') + '&boundaryState=' + locationState);
        if (res.data.success) {
            return res.data.data;
        }
        return -1;
    } catch {
        return -1;
    }
};

export const fetchGeocodingLocations = async (searchQuery) => {
    try {
        let res = await axios.post('geocoding/location?location=' + searchQuery);
        if (res.data.success) {
            return res.data.data;
        }
        return -1;
    } catch {
        return -1;
    }
};
export const fetchSearch = async (perPage, fields, brand_group_id, page, parameters, token) => {
    try {
        let searchUrl = 'properties/beta/searchElastic';

        let res = await axios.post(searchUrl + '?pageSize=' + perPage + '&fields=' + fields + '&brand_group_id=' + brand_group_id + '&page=' + page, parameters, {
            cancelToken: token
        });
        return res.data.data;
    } catch {
        // return exception error
        return -1;
    }
};
export const fetchSearchPins = async (parameters, token) => {
    try {
        let searchUrl = 'properties/beta/searchPins';
        let res = await axios.post(searchUrl, parameters, {
            cancelToken: token
        });
        return res.data;
    } catch {
        // return exception error
        return -1;
    }
};
export const fetchValuationPins = async (parameters, signal) => {
    try {
        let searchUrl = 'get-value-pins';
        let res = await axios.post(searchUrl, parameters, {
            signal
        });
        return res.data;
    } catch {
        // return exception error
        return -1;
    }
};
export const fetchCityOwner = async (brandPrependUrl, brand_group_id, parametersQuery) => {
    try {
        let $ownerUrl = 'city/owner';
        if (brandPrependUrl && brand_group_id && brand_group_id > 0) {
            $ownerUrl = 'teamowners/' + brand_group_id;
        }

        // then add city and state
        if (!parametersQuery.city) {
            parametersQuery.city = 'Wildcard';
        }
        if (!parametersQuery.state) {
            parametersQuery.state = 'CA';
        }
        if (!parametersQuery.stateOrProvince) {
            parametersQuery.stateOrProvince = 'CA';
        }

        const res = await axios.get($ownerUrl, { params: parametersQuery });
        return res.data.data;
    } catch {
        // return exception error
        return -1;
    }
};

export const fetchPropertyDetails = async (propertyUrl) => {
    try {
        const res = await axios({
            method: 'GET',
            url: propertyUrl + '?return-json=true',
            baseURL: '',
            cancelToken: ''
        });
        return res.data;
    } catch (err) {
        // return exception error
    }
};
export const fetchMortgageRates = async (startDate, endDate) => {
    try {
        const res = await axios.get(`/mortgage-rates?start_date=${startDate}&end_date=${endDate}`);
        return res.data.data;
    } catch (err) {
        // return exception error
        throw err;
    }
};

export const getPropertyScore = async (property) => {
    try {
        const res = await axios.get(`https://www.realty.com/api/properties/deal-score/${property}`);
        return res.data;
    } catch (err) {
        // return exception error
        throw err;
    }
};

export const pushToAoi = async (payload) => {
    try {
        const res = await axios.post('user/set-aoi', payload);
        return res.data;
    } catch (error) {
        // return exception error
        throw error;
    }
};

export const sendGetRequest = async (url, key = null) => {
    try {
        const res = await axios.get(url);
        return key ? res[key] : res;
    } catch (error) {
        // return exception error
        throw error;
    }
};

export const getVanityUrls = async (searchLocation) => {
    let base_url = 'vanity-urls';
    let params = new URLSearchParams();
    let type = searchLocation?.type?.toLowerCase();

    if (!type) {
        console.error('Error: Type is not defined');
        return;
    }

    params.append('type', type);

    if (searchLocation.state) params.append('state', searchLocation.state);

    if (['city', 'zip'].includes(type)) params.append(type, searchLocation[type]);

    if (['subdivision', 'community', 'neighborhood'].includes(type)) {
        let community = searchLocation.query.split(',')[0].trim().replace(/\s/g, '-');
        const city = searchLocation.city;
        params.append('city', city);
        params.append('neighborhood', community);
    }

    const url = `${base_url}?${params.toString()}`;

    return await sendGetRequest(url, 'data');
};

export const getAutocompleteResults = async (searchQuery, cancelToken) => {
    try {
        const res = await axios.get(`/search/omni?q=${searchQuery}`, { cancelToken });
        return res.data.suggestions;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled:', error.message);
            return [];
        }
        throw error;
    }
};

export const getSearchRouter = async (searchQuery) => {
    try {
        const res = await axios.get(`/search_router/${searchQuery}`);
        return res.data;
    } catch (error) {
        // return exception error
        throw error;
    }
};
